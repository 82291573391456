<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="이용약관" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<main class="page-container" id="page-terms-privacy">
					<header class="auth-header">
						<h2 class="title">이용약관</h2>
					</header>
					<div class="page-body">
						<!-- 2024-08-21 수정 이용약관 -->
						<terms-of-use />
					</div>
				</main>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import PortalPaginationRoute from '@/components/PortalPaginationRoute';
import TermsOfUse from './TermsOfUse';

export default {
	name: 'Policy',
	components: {
		PageHeader,
		PortalPaginationRoute,
		TermsOfUse,
	},
	data: () => ({}),
	computed: {},
};
</script>
